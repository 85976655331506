<template>
    <div class="login-wrap">
		<div class="login">
			<div class="main-login">
				<div class="left-login">
					<div class="login-title">
						欢迎回来
						<br>
						<br>
						一站式好物电商服务
					</div>
				</div>
				<div class="right-login">
					<div class="login-tab">
						<div class="tablist">
							<div class="tab-container">
								<div class="tab-login-scroll">
									<div class="tab-login-nav" @click="onloginOptions(true)" >
										<div :class="[isPassword?'login-active login-tab-txt':'login-tab-txt']" >帐号密码登录</div>
										<div :class="[isPassword?'login-animated':'']" ></div>
									</div>
									<div class="tab-login-nav" @click="onloginOptions(false)">
										<div :class="[isPassword?'login-tab-txt':'tab-login-txt login-active ']">验证码登录</div>
										<div :class="[isPassword?'':'login-animated']" ></div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-login-content">
							<div class="login-tabpane">
								<div class="login-top">
									<el-form ref="formlogin" :model="formlogin" :rules="rules">
										<div class="mt1">
											<el-form-item prop="user">
												<el-input prefix-icon="el-icon-user"  v-model="formlogin.user" placeholder="请输入手机号码"></el-input>
											</el-form-item>
										</div>
										<div  v-if="isPassword">
											<div class="mt1">
												<el-form-item prop="password">
													<el-input prefix-icon="el-icon-lock" v-model="formlogin.password" placeholder="请输入登录密码"  :type="pwd_flag ? 'text' : 'password'">
														    <i slot="suffix" class="haowu" :class="[pwd_flag?'hw-xianshimima_02':'hw-yincangmima_01']" style="margin-top:8px;font-size:18px;" autocomplete="auto" @click="pwd_flag=!pwd_flag" />
													</el-input>
												</el-form-item>
											</div>
											<div class="mt1" v-if="loginnum">
												<el-form-item prop="yzm">
													<el-input prefix-icon="el-icon-coffee-cup" class="login-yzm" size="" v-model="formlogin.yzm" placeholder="验证码"></el-input>
													<img :src="formlogin.yzmsrc"  class="login-yzm-img" @click="changeyzm">
												</el-form-item>
											</div>
											<div class="mt1">
												<!-- <el-checkbox v-model="remember">两周内免登录</el-checkbox> -->
												<router-link class="forget-btn" :to="{path:'forget'}">忘记密码?</router-link>
											</div>
										</div>
										<div v-else>
											<div class="mt1">
												<el-form-item prop="code">
													<el-input prefix-icon="el-icon-coffee-cup" class="login-yzm"  v-model="formlogin.code" placeholder="验证码"></el-input>
													<el-button type="primary" style="background-color: #409EFF;border-color: #409EFF;"  @click="onSendSms()" class="send_sms">{{sendText}}</el-button>
												</el-form-item>
											</div>
											<div class="mt1 clear">
												<router-link class="forget-btn" :to="{path:'forget'}">忘记密码?</router-link>
											</div>
										</div>
										<div class="mt1">
											<el-button type="primary" style="background-color: #409EFF;border-color: #409EFF;width: 100%;font-size:20px;" native-type="button" @click="onSubmit('formlogin')">登录<i class="el-icon-right el-icon--right"></i></el-button>
										</div>
										<div class="mt1">
											<router-link class="register-btn" :to="{path:'register'}">立即注册账户</router-link>
										</div>
									</el-form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {validateuser,validatepassword,validateyzm,validatecode} from "@/utils/validate"
import { mapGetters,mapState } from 'vuex'
import throttle from "lodash/throttle";
export default {
	data() {
      return {
		//表单
        formlogin: {
			user:'',//手机号码
			password:'',
			yzm:'',//图片验证码
			code:'',//短信验证码
			yzmsrc: '/api/captcha.html',
			sendnum:0,//发送短信次数
        },
		//规则
		rules: {
			  user: [
				{ required: true, validator: validateuser, trigger: 'blur' },
			  ],
			  password: [
				{ required: true, validator: validatepassword, trigger: 'blur' },
			  ],
			  yzm: [
				{required: true, validator: validateyzm, trigger: 'blur' }
			  ],
			  code:[
				{ required: true, validator: validatecode, trigger: 'blur' },
			  ]
		},
		pwd_flag:false,//控制密码显示与隐藏
		isPassword:true,//判断登录方式（密码登录，验证码登录）
		loginnum:false,//判断登录次数，大于1需要开启验证码
		timer: 0,
		remember:false,//记住密码
      }
    },
	computed: {
		//发送验证码倒计时
		sendText() {
			if (this.timer === 0) {
				return "发送验证码";
			} else {
				return `${this.timer}秒后重发`;
			}
		},
	},
	watch: {
		timer(num) {
			if (num > 0) {
				setTimeout(() => {
				this.timer = --num;
				}, 1000);
			}
    	},
	},
	
	methods:{
		//提交事件
		onSubmit:throttle(function(){
			this.$refs.formlogin.validate(async (valid) => {
			    if (valid) {
				     if(this.isPassword == true){
						   //密码登录
                           try{
								await this.$store.dispatch('getUserLogin',{mot:this.formlogin.user,pwd:this.formlogin.password,yzm:this.formlogin.yzm})
								this.$message.success('登录成功!')
								this.$router.replace({path: '/home'})	
						   }catch(error){
								this.$message.error(error.message);
								this.loginnum=true;
								this.changeyzm();
						   }
					 }else{
						  //验证码登录
						  try{
						        await this.$store.dispatch('getphoneLogin',{mot:this.formlogin.user,yzm:this.formlogin.code})
                                this.$message.success('登录成功!')
								this.$router.replace({path: '/home'})
						  }catch(error){
                                this.$message.error(error.message);
								this.loginnum=true;
								this.changeyzm();
						  }
						
					 }
				
			  }else{
				   this.$message.error('请输入正确的信息');
			  }

		  });
		},1000),
		
		//切换验证码登录和密码登录
		onloginOptions(val){
			this.$refs['formlogin'].clearValidate()// 清除表单验证
			if(val == false){
				this.isPassword = false;
			}else{
				this.isPassword = true;
			}
		},

		//切换验证码
		changeyzm(){
			this.formlogin.yzmsrc = '/api/captcha.html?'+Math.random();
		},
		
		//发送验证码
		onSendSms(){
		  this.$refs.formlogin.validateField("user", async err => {
			if (!err) {
                 try{
					await this.$store.dispatch('getSmscode',{mot:this.formlogin.user,type:2})
					this.timer=60;
					this.formlogin.sendnum+=1; 
				 }catch(error){
                    this.$message.error(error.message);
				 }				
			}
		  });
		}
	},

	created() {
		var that = this;    
		document.onkeydown = function(e) {     
			var key = window.event.keyCode;      
			if (key == 13) { 
				that.onSubmit();
			}    
		}
	},
	mounted(){
		this.changeyzm();
	}
}
</script>

<style  lang="less" scoped>
	.login-wrap{
		width: 100%;
		height: 100%;	
		position: absolute;
    	margin: -8px;	
		*{
			box-sizing: border-box;
		}
	}
	.login{
		height: 100%;
		min-height: 700px;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-image: url(//www.soso9.com/static/img/background1.png);
		.main-login{
			width: 920px;
			height: 600px;
			top: 50%;
			left: 50%;
			position: absolute;
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
			overflow: hidden;
			box-shadow: 0 0 15px rgb(0 138 233 / 15%);
			.left-login{
				float: left;
				width: 380px;
				height: 600px;
				border-radius: 10px 0 0 10px;
				background: url(//www.soso9.com/static/img/left-login.png) center center no-repeat;
				position: relative;
				padding: 0 78px;
				.login-title{
					font-size: 28px;
					color: #fff;
					letter-spacing: 9px;
					margin: 150px 0 80px 0;
				}
			}
			.right-login{
				width: 540px;
				height: 600px;
				padding: 50px 60px;
				background-color: #fff;
				position: relative;
				float: left;
				border-radius: 0 10px 10px 0;
				.login-tab{
					box-sizing: border-box;
					margin: 0;
					padding: 0;
					color: rgba(0,0,0,.65);
					font-size: 14px;
					font-variant: tabular-nums;
					line-height: 1.5;
					list-style: none;
					font-feature-settings: "tnum";
					position: relative;
					overflow: hidden;
					zoom: 1;
					.tablist{
						margin: 0 0 16px;
						border-bottom: 1px solid #e8e8e8;
						outline: none;
						-webkit-transition: padding .3s cubic-bezier(.645,.045,.355,1);
						transition: padding .3s cubic-bezier(.645,.045,.355,1);
						.tab-container{
							position: relative;
							box-sizing: border-box;
							margin-bottom: -1px;
							overflow: hidden;
							font-size: 14px;
							line-height: 1.5;
							white-space: nowrap;
							zoom: 1;
							.tab-login-scroll{
								margin-bottom: -1px;
    							overflow: hidden;
								.tab-login-nav{
									user-select:none;
									position: relative;
									display: inline-block;
									box-sizing: border-box;
									margin: 0;
									padding-left: 0;
									list-style: none;
									-webkit-transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
									transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
									transition: transform .3s cubic-bezier(.645,.045,.355,1);
									transition: transform .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
								}
								.login-tab-txt{
									position: relative;
									display: inline-block;
									box-sizing: border-box;
									height: 100%;
									margin: 0 32px 0 0;
									padding: 12px 16px;
									text-decoration: none;
									cursor: pointer;
									-webkit-transition: color .3s cubic-bezier(.645,.045,.355,1);
									transition: color .3s cubic-bezier(.645,.045,.355,1);
									font-size: 18px;
								}
								.login-active{
									font-size: 20px;
									padding: 20px 16px;
									color: #1890ff;
									font-weight: 500;
								}
								.login-animated{
									display: block;
									transform: translate3d(0px, 0px, 0px);
									width: 152px;
									position: absolute;
									bottom: 1px;
									left: 0;
									z-index: 1;
									box-sizing: border-box;
									height: 2px;
									background-color: #1890ff;
									-webkit-transform-origin: 0 0;
									transform-origin: 0 0;
									transition: transform .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1),left .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
								}
							}
						}
					}
				}
				.tab-login-content{
					width: 100%;
					display: -webkit-box;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					flex-direction: row;
					-webkit-transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
					transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
					will-change: margin-left;
					margin-left: 0%;
					.login-tabpane{
						flex-shrink: 0;
						width: 100%;
						opacity: 1;
						-webkit-transition: opacity .45s;
						transition: opacity .45s;
						.login-top{
							margin-top: 15px;
						}
					}
					// .mt1{
					// 	margin-bottom: 2rem;
					// }
				}	
				.login-yzm{
					display: inline-block;
					width: 55%;
				}
				.login-yzm-img{
					vertical-align: middle;
					width: 120px;
					height: 36px;
					padding-left: 20px;
					cursor: pointer;
				}
				.send_sms{
					width: 40%;
					margin-left: 5%;
				}
				.forget-btn{
					float: right;
    				color: #999;
					text-decoration:none;
				}
				.register-btn{
    				color: #999;
					text-decoration:none;
				}
			}
		}
	}
	.clear::after{
		display:block; 
        clear:both; 
        height:0; 
        content: ""; 
        visibility: hidden; 
        overflow:hidden; 
    }
    

 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #409EFF;
    border-color: #409EFF;
 }
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
     color: #409EFF;
}
</style>